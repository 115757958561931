import qs from 'qs';
import { useState } from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { SubscribeModal } from './SubscribeModal';
import {
    FooterNavCol,
    FooterNavRow,
    FooterSubscribe,
    StyledFooter
} from './styles/Footer.styled';
import { MyButton } from './styles/MyComponents.styled';

interface Item {
    title: string
    links: NavLink[]
}
interface NavLink {
    name?: string
    url: string
    img?: string
    target?: string
}

const Footer = () => {
    const { t } = useTranslation()
    const nav: Item[] = [
        {
            title: t('Products'),
            links: [
                { name: t('PoweLego'), url: 'https://www.powerlego.com/', target: '_blank' },
                { name: t('EIQhome'), url: 'https://www.eiqhome.com/', target: '_blank' },
                { name: t('EIQShopping'), url: `https://www.eiqshopping.com/`, target: '_blank' },
                { name: t('ESIID.io'), url: `https://www.esiid.io/`, target: '_blank' },
                { name: t('BillReader'), url: `https://www.billreader.com/`, target: '_blank' },
            ],
        },
        {
            title: t('Support'),
            links: [
                { name: 'Contact Us', url: '/contact' },
                { name: 'Terms of Service', url: '/terms_of_service' },
                { name: 'Privacy Policy', url: '/privacy_policy' },
            ],
        }
    ]

    const location = useLocation()
    let [showSubscribe, setShowSubscribe] = useState(false)

    const { address, zipcode, city, state } = qs.parse(
        location.search,
        { ignoreQueryPrefix: true }
    )

    return (<>
        <StyledFooter>
            <Container>
                <FooterNavRow>
                    {/* <div className='d-flex align-items-center'>
                        <img src={'https://www.esiid.io/static/media/eiqdigtal_logo.45e730d8ad3b788824f9.png'}
                            alt="logo"
                            className='logo'
                            width={200}
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                window.open('https://www.eiqdigital.com/')
                            }}
                        />
                    </div> */}

                    <div className='d-flex gap-3' style={{ flex: 1 }}>
                        {nav.map((val, idx) => (
                            <FooterNavCol key={idx}>
                                <h6>{val.title}</h6>
                                {val.links.map((item, itemIdx) => {
                                    return (
                                        <div key={itemIdx}>
                                            <a href={item.url} rel="noreferrer" target={item.target}>
                                                {item.name}
                                            </a>
                                        </div>
                                    )
                                })}
                            </FooterNavCol>
                        ))}
                    </div>

                    <FooterSubscribe>
                        <h5>Set contract expiration reminder</h5>
                        <p>We will send you a reminder message before the expiration date you set</p>
                        <MyButton className='mb-3'
                            style={{
                                width: 100,
                                height: 36,
                                padding: 5
                            }}
                            bkcolor='#00bdd6'
                            onClick={() => {
                                setShowSubscribe(true)
                            }}
                        >Alert</MyButton>
                        <div className='d-flex gap-3'>
                            <img src="/images/icon_amazon.png" alt="" className='link-logo' />
                            {/* <img src={'https://www.esiid.io/static/media/eiqdigtal_logo.45e730d8ad3b788824f9.png'}
                                alt=""
                                className='link-logo logo-eiq'
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    window.open('https://www.eiqdigital.com/')
                                }}
                            /> */}
                        </div>
                        <div className='d-flex gap-3 mt-4'>
                            <img src="/images/logo_eiqdigital.png" width={220} alt="" />
                        </div>
                    </FooterSubscribe>
                </FooterNavRow>
            </Container>
        </StyledFooter>
        <SubscribeModal
            show={showSubscribe}
            handleClose={() => {
                setShowSubscribe(false);
            }}
            address={address}
            zipcode={zipcode}
            city={city}
            state={state}
        />
    </>
    )
}

export default Footer