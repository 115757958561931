import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { Container, Form } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { contactUs } from '../api/api'
import Footer from '../components/footer'
import Header from '../components/header'
import { LoadingRow } from '../components/loading-row'
import { FooterWapper } from '../components/styles/Footer.styled'
import { Card, MyButton } from '../components/styles/MyComponents.styled'
import { ContentPage } from '../components/styles/Page.styled'

export const ContactUs = () => {
    const [submitStatus, setSubmitStatus] = useState(false);
    const [loading, setLoading] = useState(false)

    const handleSubmit = (event: any) => {
        event.preventDefault();
        let { name, email, company, message } = event.target.elements;
        let { value: _name } = name
        let { value: _email } = email
        let { value: _company } = company
        let { value: _message } = message

        if (_name == null || _name.trim() === "") {
            toast.error('Please enter your name.');
            return;
        }
        if (_email == null || _email.trim() === "") {
            toast.error('Please enter the correct email format.');
            return;
        }
        if (_company == null || _company.trim() === "") {
            toast.error('Please enter your company.');
            return;
        }
        if (_message == null || _message.trim() === "") {
            toast.error('Please enter your message.');
            return;
        }
        setLoading(true)
        contactUs(_name, _email, _company, _message).then((res) => {
            if (res.status === '1') {
                setSubmitStatus(true)
            } else {
                toast.error('Network error, please try again')
            }
            setLoading(false)
        })
    }

    return (
        <FooterWapper>

            <Header />

            <ContentPage>
                <Container>
                    <h1>ContactUs</h1>
                    <h2>Our team is happy to answer your questions.Fill out the form and we'll be in touch as soon as possible</h2>

                    <Card >
                        {!submitStatus && !loading && <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-3">
                                <Form.Label>Your Name</Form.Label>
                                <Form.Control type='name' name='name' />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type='email' name='email' />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Company</Form.Label>
                                <Form.Control type='company' name='company' />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Message</Form.Label>
                                <Form.Control type='message' as="textarea" name='message' />
                            </Form.Group>

                            <div className='d-flex justify-content-center mt-5'>
                                <MyButton type='submit'
                                    style={{
                                        width: 100,
                                        height: 36,
                                        padding: 5
                                    }}
                                    bkcolor='#00bdd6'
                                >Submit</MyButton>
                            </div>
                        </Form>}
                        {loading && <LoadingRow />}
                        {submitStatus && <div style={{ minHeight: 300 }} className='d-flex justify-content-center align-items-center gap-3'>
                            <FontAwesomeIcon icon='check-circle' style={{ color: '#89cb6d', height: 30 }} />
                            <div>
                                <div className='mb-3' style={{ fontSize: 20 }}>Submit Successfully.</div>
                                <div style={{ fontSize: 20 }}>We will contact you ASAP.</div>
                            </div>
                        </div>}
                    </Card>
                </Container>
            </ContentPage>

            <Footer />
        </FooterWapper>
    )
}
